/*********************
Primitives
*********************/

// Base Grey
$base-grey-0: #FFFFFF;
$base-grey-50: #FBFAF7;
$base-grey-100: #F1EFE7;
$base-grey-200: #EBE7DB;
$base-grey-300: #E2DBCB;
$base-grey-400: #DCD5C1;
$base-grey-500: #D3CAB1;
$base-grey-600: #C0B8A1;
$base-grey-700: #968F7E;
$base-grey-800: #746F61;
$base-grey-900: #59554A;
$base-grey-1000: #000000;

// Base Green
$base-green-50: #F5F9F3;
$base-green-100: #E1EBDB;
$base-green-200: #D2E2CA;
$base-green-300: #BDD5B1;
$base-green-400: #B1CDA2;
$base-green-500: #9DC08B;
$base-green-600: #7A956C;
$base-green-700: #5B6F51;
$base-green-800: #414F3A;
$base-green-900: #1b201b;



/*********************
Colors
*********************/

//Text
$text-dark: $base-green-900;
$text-dark-muted: #F9EDA8;
$text-light: $base-grey-0;
$text-light-muted: $base-green-400;

// Background
$background-primary: $base-grey-0;
$background-secondary: #1b201b;

// Surface
$surface-primary: $base-green-100;
$surface-primary-elevated: $base-green-500;
$surface-primary-elevated-2: $base-green-700;

$surface-secondary: $base-green-800;
$surface-secondary-elevated: $base-green-700;
$surface-secondary-elevated-2: $base-green-500;

/*********************
FONTS
*********************/
$font-family-main:'Work Sans', sans-serif;

$text-4xl: calc(1.2rem + 4.5vw);
$text-3xl: calc(1rem + 2.8vw);
$text-2xl: calc(1rem + 2vw);
$text-xl: calc(1.35rem + .5vw);
$text-lg: calc(1.1rem + .5vw);
$text-base: calc(1.1rem + .5vw);
$text-sm: calc(1rem + .2vw);


$font-weight-light:300;
$font-weight-regular:400;
$font-weight-mid:500;
$font-weight-semibold:600;
$font-weight-bold:800;
 


/*********************
BREAKPOINTS
*********************/

@mixin breakpoint($point) {
    @if $point == sm {
      @media (min-width: 544px) { @content ; } // iphone X og ned
   }
     @else if $point == md {
      @media (min-width: 850px) { @content ; } // iPad og ned
   }
   @else if $point == lg {
    @media (min-width: 1024px) { @content ; } 
    }
    @else if $point == xl {
    @media (min-width: 1400px) { @content ; } 
    }
    @else if $point == xxl {
      @media (min-width: 1920px) { @content ; } 
    }
    @else if $point == xxxl {
      @media (min-width: 2200px) { @content ; } 
    }
 }