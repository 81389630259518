@import "_variables.scss";
@import "_typography.scss";
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400&display=swap');

// Grunnstil
body {
  margin: 0 auto;
  font-family: $font-family-main;
  color: $text-dark;
  font-weight: $font-weight-regular;
  font-size: $text-base;
  margin: auto;
  line-height: 1.4;
  letter-spacing: -.3px;
  transition: .3s cubic-bezier(0.55, 0.09, 0.68, 0.53) all;
  background-color: $background-primary;
  overflow-x: hidden;
  scroll-behavior: smooth;

  a {
    color: $text-dark;
  }

  button {
    font-family: $font-family-main;
    font-weight: $font-weight-regular;
  }

  img {
    max-width: 100%;
    margin: auto;
  }

  .privacy_link {
    a {
      color: $text-dark;
    }
  }

  .nospace {
    margin: 0;
    padding: 0;

    &.bottom {
      margin-bottom: inherit;
      padding-bottom: inherit;
    }
  }
  
  &.dark {
    background-color: $background-secondary;
    color: $text-light;

    a {
      color: $text-light;
    }

    .ckb-navigation {
      a {
        color: $text-light;

        &:before {
          color: white !important;
        }
      }
    }

    .hero-text-wrapper {
      color: $text-light;
    }

    .m-button {
      color: $text-dark;

      &:hover {
        color: $text-light;
      }
    }

    .top-gradient {
      background: linear-gradient(rgb(27 32 27 / 100%), rgb(27 32 27 / 0%));
    }
  }
  
}


// Gradient i topp
.top-gradient {
  position: fixed;
  height: 60px;
  width: 100%;
  top: 0;
  left: 50%;
  opacity: 1;
  z-index: 1;
  transform: translate(-50%, 0%);
  background: linear-gradient(#edf1d6 0%, #0f282700 100%);

  @include breakpoint(md) {
    height: 80px;
  }

  @include breakpoint(lg) {
    height: 120px;
  }
}


// Hero
.hero-wrapper {
  padding: 24px 16px 50px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 390px;

  @include breakpoint(lg) {
    padding: 0 4vw;
    justify-content: left;
  }
  

  .arrow {
    display: flex;
    width: 22px;
    margin: auto;
    margin-top: 25px;
    animation: fadeIn 1s forwards;
    animation-delay: 2.2s;
    opacity: 0;

    @include breakpoint(lg) {
      display: none;
    }
  }

  .hero-text {
    margin: 0;
    

    @include breakpoint(lg) {
      margin: 120px auto 0 auto;
    }
  }

}


// Om Christian på forsiden
#info-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  max-width: 1920px;
  margin: 0 auto 15vh auto;
  justify-content: center;
  align-items: center;

  @include breakpoint(md) {
    margin: 20vh auto;
  }
  @include breakpoint(lg) {
    padding: 0 8vw;
  }
  
  .img-hero {
    width: 100%;
    position: relative;
    opacity: 0;
    

    @include breakpoint(md) {
      width: 45%;
    }

    .ih-wrapper {
      @include breakpoint(md) {
        max-width: 660px;
      }
    }

    img {
      max-height: 485px;
      border-radius: 8px 150px 8px 8px;
      object-fit: cover;
  
      @include breakpoint(md) {
        max-height: 90vh;
        width: 100%;
      }
    }

    .namespin-wrapper {
      img {
        border-radius: 0;
      }
    }
  }

  .ckb-about-text {
    width: 100%;
    height: fit-content;
    padding: 24px 0 0 0;

    @include breakpoint(md) {
      width: 50%;
      padding: 0 0 0 5%;
    }
  
    .ca-text {
      transition: .3s all;
      animation-delay: .4s;
      opacity: 0;
      text-align: center;

      @include breakpoint(md) {
        text-align: left;
      }

      p {
        margin: 24px auto;

        @include breakpoint(xl) {
          margin: 32px auto;
        }
      }

      .ckb-topic-marker {
        margin: auto;

        @include breakpoint(md) {
          margin: 0 auto 0 0;
        }
      }
    }
  }
}


// Teksteffekt
.namespin-wrapper {
  animation: spin 6s linear infinite;
  position: absolute;
  top: -30px;
  left: -30px;
  height: 60px;
  width: 60px;
  opacity: .5;
  display: none;

  @include breakpoint(md) {
    height: 70px;
    width: 70px;
    top: -30px;
    left: -10px;
    animation: spin 4s linear infinite;
    display: block;
  }
  @include breakpoint(lg) {
    height: 90px;
    width: 90px;
    top: -30px;
    left: -30px;
  }
}


// Flere jobber på forsiden
.morejobs {
  transition: .3s all;
  padding: 0 16px;
  max-width: 720px;
  margin: 8vh auto;

  @include breakpoint(lg) {
    margin: 8vh auto 5vh auto;
    padding: 0 8vw;
  }

  #showpanel {
    display: none;
    margin-bottom: 60px;
  }
}


// Logorad på forsiden
.clients-highlight {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  transition: .3s all;
  padding: 0 16px;
  max-width: 1920px;
  margin: 15vh auto;

  @include breakpoint(md) {
    margin: 20vh auto;
  }
  @include breakpoint(lg) {
    padding: 0 8vw;
  }

  .client-logo {
    width: 26%;
    margin: 6% 12%;
    align-self: center;
    opacity: 0;

    @include breakpoint(md) {
      width: 10%;
      margin: 3% 5%;
    }
    @include breakpoint(xl) {
      width: 8%;
      margin: 3% 6%;
    }
  }

  .small {
    max-width: 90px;
    display: flex;

    @include breakpoint(lg) {
      max-width: 100%;
    }
  }
  .msmall {
    max-width: 90px;
    display: flex; 


    @include breakpoint(lg) {
      max-width: 140px;
    }
  }
  .xsmall {
    max-width: 70px;
    display: flex;

    @include breakpoint(lg) {
      max-width: 80px;
    }
  }
}


// Text-slide-up effekt
.ml16, .ml16-fp-1, .ml16-fp-2, .ml16-fp-3 {
  overflow: hidden;
  animation: fadeIn 1s forwards;
  animation-delay: .1s;
  opacity: 0;
}
.ml16 .letter, .ml16-fp-1 .letter, .ml16-fp-2 .letter, .ml16-fp-3 .letter {
  display: inline-block;
  line-height: 0.5em;
}
.ml16-p-1, .ml16-p-2 {
  overflow: hidden;
  animation: fadeIn 2s forwards;
  animation-delay: .3s;
  opacity: 0;
}
.ml16-p-1 .letter, .ml16-p-2 .letter {
  display: inline-block;
  line-height: 1em;
}


// Teksteffekt
h1.ml16-fp-1, h1.ml16-fp-2, h1.ml16-fp-3 {
  font-size: 7vw;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  animation: fadeIn 2s forwards;
  animation-delay: .3s;
  opacity: 0; 

  @include breakpoint(md) {
    font-size: $text-3xl;
  }
}


// Teksteffekt
.ml16-p-2 {
  margin-bottom: 0;
  margin-top: 0;
}


// Mus-effekt
a, button {
  @include breakpoint(lg) {
    cursor: none;
  }
}
.cursor-dot,
.cursor-dot-outline {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out,
  transform 0.3s ease-in-out;
  z-index: 99999;
}
.cursor-dot {

  @include breakpoint(lg) {
    $size: 8px;
    width: $size;
    height: $size;
    background-color: $surface-secondary-elevated;
  }
}
.cursor-dot-outline {

  @include breakpoint(lg) {
    $size: 40px;
    width: $size;
    height: $size;
    background-color: rgba($surface-secondary-elevated, 0.5);
  }
}


// Link hover effect
a, button {
  &.l1 {
    position: relative;
    text-decoration: inherit;
    color: $text-dark;
    padding-bottom: 3px;
  }

  &.l2 {
    position: relative;
    text-decoration: inherit;
  }

  &.l1::before, &.l2::before, &.l1::before {
    content: '';
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $surface-secondary-elevated;
    transform-origin: bottom left;
    transition: transform 0.3s ease-in-out;
  }

  &.l1:hover::before, &.l2:hover::before, &.l1:hover::before {
    transform: scaleX(0);
    transform-origin: bottom right;
  }
}


// Header for jobber på forsiden
.job-link-wrapper {
  padding: 0 16px 20px 16px;
  max-width: 1920px;
  margin: auto;
  
  @include breakpoint(lg) {
    padding: 0 8vw 40px 8vw;
  }

  a {
    color: $text-light;
    
    &:first-child {
      margin-right: 10px;
    }

    &.current {
      color: $surface-secondary-elevated;
      text-decoration: none;
    }
  }
  .markers {
    margin-top: 10px; 
    opacity: 0;
  }
}


// Topic marker
.ckb-topic-marker, .ckb-topic-marker-two {
  width: 80px;
  overflow: hidden;
  color: $text-dark;

  &.dark {
    color: $text-dark;
  }

  &.ckb-topic-marker {
    opacity: .4;
  }

  .content {
    font-size: .75rem;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation: roll 20s linear infinite;
    -moz-animation: roll 20s infinite linear;
    -o-animation: roll 20s infinite linear;
    animation: roll 20s linear infinite;
    will-change: transform;

    @include breakpoint(lg) {
      font-size: .92rem;
    }
  }

  @keyframes roll {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(-500px);
    }
    100% {
      transform: translateX(0px);
    }
  }
}

// Utvalg
.selection-list {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: auto;
  padding: 0 16px;
  flex-wrap: wrap;
  transition: .3s all;

  @include breakpoint(lg) {
    padding: 0 4vw;
  }

  @include breakpoint(lg) {
    flex-direction: row;
  }
  .selection-list-item {
    margin: 0 0 5vh 0;
    width: 100%;

    video {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
    }

    img {
      border-radius: 8px;
    }

    @include breakpoint(lg) {
      width: 48%;
      margin: 0 0 10vh 0;
    }

    &.first {
      @include breakpoint(lg) {
        margin: 0 4% 0 0;
      }
    }
    &.full {
      width: 100%;
    }
  }
}


// Jobber singelvisning
.j1intro_jobb {
  padding-top: 19.5vh;
  padding-bottom: 15vh;
  margin-bottom: 0px;
  max-width: 100%;
  text-align: center;
  

  @include breakpoint(lg) {
    max-width: 80%;
    text-align: left;
  }
    h1 {
      margin-bottom: 0;
      hyphens: auto;
      animation: fadeIn 2s forwards;
      animation-delay: .1s;
      opacity: 0;
      color: $text-dark;
      line-height: 1.7;
      margin: 0 0 20px 0;
    }
    h4 {
      animation: RevealThings 1s forwards;
      animation-delay: 0.5s;
      opacity: 0;
    }
}
.job-single-list {
  display: flex;
  justify-content: center;
  max-width: 1600px;
  margin: auto;
  padding: 0 20px;
  transition: .3s all;

  @include breakpoint(lg) {
    padding: 0 4vw;
  }

  video {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .jsingle {
    animation: RevealThings 1s forwards;
    animation-delay: 0.5s;
    opacity: 0;
    
    img {
      border-radius: 8px;
    }

    .jsingle_tekst {
      max-width: 700px;
      margin: auto;
      text-align: left;
      padding-top: 8vw;
      padding-bottom: 8vw;
      font-size: $text-sm;

      @include breakpoint(sm) {
        max-width: 70vw;
      }
    
      @include breakpoint(lg) {
        padding-left: 0;
        padding-right: 0;
        font-size: $text-base;
      }

      @include breakpoint(xl) {
        max-width: 50vw;
      }

      @include breakpoint(xxl) {
        max-width: 40vw;
      }

      .nomargintop {
        margin-top: 0;
      }

    }
    .fiftyfifty-img {
      display: flex;

      img {
        width: 49%;
        height: 100%;

        &:first-child {
          margin-right: 2%;
        }
      }
    }
    &.center {
      text-align: center;
    }
  }
}

.privacy_link {
  text-align: center;
  font-size: .8rem;
  color: $text-dark;
  opacity: 0.8;
  padding-bottom: 10px;

  &.dark {
    color: $text-light;
  }
}

//General page
.general-wrapper {
  margin: auto;
  padding: 0px 16px 0 16px;
  max-width: 800px;
  font-size: $text-sm;

  @include breakpoint(md) {
    padding: 0px 16px 0 16px;
  }
  @include breakpoint(lg) {
    padding: 0px 8vw 0 8vw;
    font-size: $text-base;
  }
  @include breakpoint(xxl) {
    max-width: 1100px;
  }
}

//Bottom links
.bottom-links {
  padding: 0 0 40px 0;
  max-width: 800px;
  width: fit-content;
  margin: auto;
  font-size: .85rem;
  opacity: .4;
}

// Mobil / desktop omtimalisering
.desktop {
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}
.mobile {
  display: block;
  @include breakpoint(md) {
    display: none;
  }
}


// Hide / show
.show {
  opacity: 1;
  pointer-events: all;
}
.hide {
  opacity: 0;
  pointer-events: none;
}


// Enkel reveal-effekt (fade-in med rotate og scale)
.reveal {
  animation: RevealThings 1s forwards;
  opacity: 0;
}
.reveal-slight {
  animation: RevealThingsSlight 1s forwards;
  opacity: 0;
}
.reveal-fx {
  animation: reveal-fx 1s forwards;
  opacity: 0;
}


// Cases på forsiden med bilde
.fiftyfifty-ref-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  max-width: 1920px;
  margin: auto;
  font-size: .95rem;

  @include breakpoint(lg) {
    padding: 0 8vw;
    font-size: 1.2rem;
  }

  .item {
    width: 100%;

    @include breakpoint(lg) {
      width: 45%;
    }

    &:nth-child(odd) {
      padding: 0 0 10vh 0;

      @include breakpoint(lg) {
        padding: 0 5% 0 0;
      }
    }

    &:nth-child(even) {
      padding: 0 0 10vh 0;

      @include breakpoint(lg) {
        padding: 0 0 0 5%;
      }
    }

    &:last-child {
      padding: 0;

      @include breakpoint(lg) {
        padding: 0 0 0 5%;
      }
    }

    .job-element-single {
      text-decoration: none;

      .job-element-image {
        line-height: 0;

        img {
          border-radius: 8px;
          transition: .3s all;
        }
        video {
          max-width: 100%;
          height: auto;
          border-radius: 8px;
          transition: .3s all;
        }
      }
      .job-element-text {
        transition: .5s all;
        font-size: $text-base;

      }
      &:hover {
        .job-element-image {
          img {
            opacity: 1;

            @include breakpoint(lg) {
              transform: scale(1.01);
            }
          }
        }
        .job-element-text {
          @include breakpoint(lg) {
            transform: translate(-5px, 5px) !important;
          }
        }
      }
    }
  }

  .second {
    @include breakpoint(lg) {
      padding-top: 60px;
    }
  }
  .third, .fifth {
    @include breakpoint(lg) {
      margin-top: 12vh;
    }
  }

}


// Ekstra rad med cases
.secondary-ref-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 140px;
  padding: 0 16px;
  max-width: 1920px;
  margin: 12vh auto 0 auto;

  @include breakpoint(lg) {
    padding: 0 8vw;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }
  @include breakpoint(xl) {
    grid-gap: 60px;
  }

  img {
    border-radius: 8px;
  }

  a {
    text-decoration: none;

    p {
      @include breakpoint(lg) {
        margin-top: 12px;
      }
    }

    .job-element-image {
      img {
        transition: .3s all;
      }
    }
    .job-element-text {
      transition: .5s all;
    }

    &:hover {
        .job-element-image {
          img {
            opacity: 1;
  
            @include breakpoint(lg) {
              transform: scale(1.01);
            }
          }
        }
        .job-element-text {
          @include breakpoint(lg) {
            transform: translate(-2.5px, 2.5px) !important;
          }
        }
      
    }
  }
}

// Sublead i hero
.sublead {
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s forwards;
  animation-delay: 1.6s;
  opacity: 0;
  color: $text-dark-muted;


  @include breakpoint(sm) {
    padding-top: 16px;
  }
  
  @include breakpoint(md) {
    padding-top: 24px;
  }

  h4 {
    font-size: $text-base;
    font-weight: 500;

    @include breakpoint(md) {
      font-size: $text-lg;
    }
  
    @include breakpoint(xl) {
      font-size: $text-xl;
    }
  }

  .heart {
    margin: 0;
    margin-right: 8px;
    animation: animateHeart 3.5s infinite;
    font-size: calc(1.05rem + .5vw);

    @include breakpoint(md) {
      font-size: calc(1.15rem + .5vw);
    }

    @include breakpoint(lg) {
      font-size: calc(1.3rem + .5vw);
    }

    @include breakpoint(xl) {
      font-size: calc(1.6rem + .5vw);
      margin-right: 16px;
    }

    @include breakpoint(xxxl) {
      font-size: calc(1.8rem + .5vw);
    }
  }
}


// Cases på forsiden
#cases {
  margin: auto;

 .case-element {
   border-bottom: 1px solid $surface-secondary-elevated-2;
   display: flex;
   justify-content: space-between;
   align-items: center;
   transition: .3s all;
   padding: 12px 0 12px 0;
   text-decoration: none;
   font-size: .95rem;
   
   &:first-child {
    padding-top: 0;
   }

   @include breakpoint(lg) {
    font-size: 1.2rem;
   }

   .description {
     display: flex;
     align-items: center;
     flex-wrap: wrap;

     @include breakpoint(md) {
       width: 100%;
     }

    .title {
      width: 100%;
      margin-bottom: 4px;
      font-weight: $font-weight-mid;
 
      @include breakpoint(md) {
        width: 45%;
        margin-bottom: 0;
        margin-right: 16px;
      }
    }
    .type {
     width: 100%;
     color: $text-dark-muted;
 
     @include breakpoint(md) {
       width: auto;
     }
   }
   }

   .more {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    width: 25%;
   }

   svg {
     width: 19px;

     @include breakpoint(lg) {
       width: 24px;
     }
   }

   &:last-child {
     margin-bottom: 0;
   }

   p {
     margin: 0;
   }

   img {
     margin: 0;
   }
 } 
}


// Se mer - Fungerende footer
.se-mer {
  transition: .3s all;
  padding: 0 16px;
  max-width: 1920px;
  margin: 15vh auto;
  opacity: 0;
  transform: .3s all;

  @include breakpoint(md) {
    margin: 20vh auto;
  }
  @include breakpoint(lg) {
    padding: 0 8vw;
  }

  .se-content {
    background: $text-light;
    color: $text-dark;
    padding: 80px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    animation: RevealThings 1s forwards;
    opacity: 0;

    @include breakpoint(md) {
      padding: 140px 80px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    .heading {
      margin-bottom: 40px;

      @include breakpoint(md) {
        margin-bottom: 0;
      }

      h2 {
        margin: 0;
      }
    }
  
    .links {
      h4 {
        margin-bottom: 24px;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
  
      a.l1, button.l1 {
        color: $text-dark;
      }
    }
  }
}


// Magnetic button
.m-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0;
  padding: 0 16px;
  width: auto;
  height: 44px;
  border-radius: 50px;
  border: 1px solid $surface-secondary-elevated-2;
  background-color: $surface-secondary-elevated-2;
  color: $text-dark;
  outline: none;
  margin: auto;
  transition: transform .25s linear, color .1s linear, background .15s linear;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;

  @include breakpoint(md) {
    margin: 0 auto 0 0;
    height: 48px;
  }
  @include breakpoint(lg) {
    height: 55px;
    padding: 0 24px;
    cursor: none;
  }

  &.regular {
    transition: .3s all;

    &:hover {
      border: 1px solid $surface-secondary-elevated-2;
      background-color: transparent;
    }
  }
  
  &.right {
    margin: 0;
  }

  &.none {
    height: 150px;
    width: 150px;
    margin: auto;
    border-radius: 100%;
    padding: 0;
    border: none;

    @include breakpoint(lg) {
      height: 200px;
      width: 200px;
    }

    &:hover {
      background-color: transparent;
      color: white;
    }
  }

  .round {
    text-align: center;
    margin: auto;
    border: 1px solid transparent;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
    cursor: pointer;
    transition: .3s all;
    font-size: .8rem;

    @include breakpoint(lg) {
      cursor: none;
      height: 200px;
      width: 200px;
      font-size: .9rem;
    }

    &:hover {
      border: 1px solid $surface-secondary-elevated-2;
    }


  }

  > span {
    display: inline-block;
    position: relative;
    z-index: 2;
    transition: transform .15s linear;
    font-size: .95rem;

    @include breakpoint(lg) {
      font-size: 1.2rem;
    }
  }

}



.simple-footer-wrapper {
  height: 80vh;
  min-height: 600px;
  display: grid;
  align-items: center;
  align-content: center;
  text-align: center;

  &.dark {
    a {
      color: white !important;
    }
  }
}



.scroll-down {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto auto 24px auto;
  width: 34px;
  height: 55px;
}

.scroller {
  width: 1px;
  height: 50px;
  border-radius: 5px;
  background-color: $base-green-700;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  70% { transform: translateY(-10px); opacity: 1; }
  100% { transform: translateY(-20px); opacity: 0;}
}





// Effekter
@keyframes RevealThings {
  0% {
    transform: translate3d(0px, 40px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 3deg);
    opacity: 0;
    transform-style: preserve-3d;
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); 
    opacity: 1; 
    transform-style: preserve-3d;
    opacity: 1;
  }
}
@keyframes RevealThingsSlight {
  0% {
    transform: translate3d(0px, 40px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 3deg);
    opacity: 0;
    transform-style: preserve-3d;
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); 
    opacity: 1; 
    transform-style: preserve-3d;
    opacity: 0.4;
  }
}
@keyframes reveal-fx {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes secondaryImageOverlayIn {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes secondaryImageOverlayOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(- 100%);
  }
}
@keyframes fadeIn-slight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .4;
  }
}


@keyframes animateHeart
{
  0%
  {
    transform: scale( .92 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .92 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .92 );
  }
  100%
  {
    transform: scale( .92 );
  }
}


.center {
  text-align: center;
  display: block;
}
.fullwidth {
  width: 100%;
}



.shape-wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  animation: fadeIn 1s forwards;
  animation-delay: 1.2s;
  opacity: 0;

  .shape {
    width: 250px;
    height: 350px;
    background: linear-gradient(64.00916346799386deg, rgba(46, 56, 46,1) 23.44140625%,rgba(46, 56, 46,1) 23.44140625%,rgba(26, 29, 26,1) 79.84765625%);
    animation: wave 4.0s ease-in-out infinite;

    @include breakpoint(lg) {
      width: 400px;
      height: 400px;
    }
  }
}

@keyframes wave {
  0%, 100% {
    border-radius: 70% 30% 72% 28% / 27% 61% 39% 73%;
  }
  50% {
    border-radius: 77% 23% 54% 46% / 40% 38% 62% 60%;
  }
}



@keyframes logohover {
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}