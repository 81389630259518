/*********************
H-tags
*********************/

h1 {
   font-size: $text-4xl;
   font-weight: $font-weight-regular;
   margin: 10px 0;
   font-family: 'El Messiri', sans-serif;
 
   @include breakpoint(lg) {
     margin: 20px 0;
   }
 
   &.long {
     font-size: $text-3xl;
 
     @include breakpoint(md) {
      font-size: $text-3xl;
     }
 
     @include breakpoint(lg) {
       margin: 20px 0;
     }
   }
 }

 h2 {
   font-size: $text-3xl;
   
   font-weight: $font-weight-regular;
   margin: 10px 0;
   font-family: 'El Messiri', sans-serif;
 
   @include breakpoint(lg) {
     margin: 20px 0;
 
   }
 }

 h3 {
   font-size: $text-2xl;
   font-weight: $font-weight-regular;
   margin: 10px 0;
   font-family: 'El Messiri', sans-serif;
 
 
   @include breakpoint(lg) {
     margin: 20px 0;
 
   }
 }
 
 h4 {
   font-size: $text-base;
   font-weight: $font-weight-light;
   margin: 10px 0;
 
 
   @include breakpoint(lg) {
     margin: 20px 0;
   }
   @include breakpoint(xl) {
     font-size: $text-lg;
     margin: 20px 0;
   }
 }